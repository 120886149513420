import Image from "next/image";

export default function Footer({ company }) {
    return (
        <>
            <footer className="footer-section">
                <div className="footer-wrapper pt-120 position-relative z-1 overflow-hidden">
                    <Image
                        src={'/img/shapes/texture-bg.png'}
                        alt="rodape"
                        style={{ zIndex: -1 }}
                        fill />
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-5 col-lg-5">
                                <div className="footer-widget widget-basic">
                                    <h3 className="widget-title-large mb-4 text-white">Tem alguma dúvida? Fale conosco e te ajudamos...</h3>
                                    <p className="mb-40">Nossos vendedores possuem anos de experiência para tirar todas suas dúvidas e te dar o melhor atendimento do Brasil.</p>
                                    <div className="phone-box d-flex align-items-center">
                                        <span className="icon-wrapper d-inline-flex align-items-center justify-content-center rounded-circle bg-primary text-white"><i className="flaticon-phone-call"></i></span>
                                        <div>
                                            <a href={`tel:+55${company.phone}`}>
                                                <h4 className="text-white ms-3 mb-0">
                                                    {
                                                        company.phone.length === 10
                                                            ? `(${company.phone.substring(0, 2)}) ${company.phone.substring(2, 6)}-${company.phone.substring(6, 10)}`
                                                            : company.phone.length === 11
                                                                ? `(${company.phone.substring(0, 2)}) ${company.phone.substring(2, 7)}-${company.phone.substring(7, 11)}`
                                                                : company.phone
                                                    }
                                                </h4>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-7">
                                <div className="ms-lg-5 ms-xl-0 mt-5 mt-lg-0">
                                    <div className="row mt-30">
                                        <div className="col-sm-4">
                                            <div className="footer-widget footer-nav-widget mb-5 mb-sm-0">
                                                <div className="widget-title text-white mb-3 h6">Sobre Nós</div>
                                                <ul className="footer-nav">
                                                    <li><a href="/sobre-nos">Sobre</a></li>
                                                    <li><a href="/contato">Contato</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="footer-widget footer-nav-widget mb-5 mb-sm-0">
                                                <div className="widget-title text-white mb-3 h6">Links Rápidos</div>
                                                <ul className="footer-nav">
                                                    {/* <li><a href="/novos">0 km</a></li> */}
                                                    <li><a href="/seminovos">Estoque</a></li>
                                                    {/* <li><a href="/blindados">Blindados</a></li> */}
                                                    <li><a href="/financiamento">Financiamento</a></li>
                                                    <li><a href="/venda-seu-veiculo">Venda Seu Veículo</a></li>
                                                    {/* <li><a href="/seguro">Seguro</a></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="footer-social d-inline-block text-start">
                                                <div className="text-white h6">Siga-nos</div>
                                                <ul className="footer-social-list">
                                                    {company.facebook ? <li><a href={company.facebook} target={'_blank'} rel="noreferrer" aria-label="Acesse o facebook da nossa loja"><i className="fab fa-facebook-f"></i></a></li> : null}
                                                    {company.instagram ? <li><a href={company.instagram} target={'_blank'} rel="noreferrer" aria-label="Acesse o instagram da nossa loja"><i className="fab fa-instagram"></i></a></li> : null}
                                                    {company.youtube ? <li><a href={company.youtube} target={'_blank'} rel="noreferrer" aria-label="Acesse o youtube da nossa loja"><i className="fab fa-youtube"></i></a></li> : null}
                                                    <li><a href={'https://www.tiktok.com/@hlcar_auto'} target={'_blank'} rel="noreferrer"><i className="fab fa-tiktok"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-sm-7">
                                    <div className="copyright-text">
                                        <p className="mb-0">&copy; Todos os direitos reservados. Desenvolvido por &nbsp;&nbsp;<a href="https://bndv.com.br" target="_blank" rel="noreferrer">BNDV - Banco Nacional de Veículos</a></p>
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <div className="copyright-links text-start text-sm-end mt-1 mt-sm-0">
                                        <a href="/termos">Termo de Uso e Política de Privacidade</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}